export const state = () => ({
  enabled: false,
  logo: null,
  product: {},
  default_shipping_method: 'digital',
});

export const mutations = {
  setWhiteLabel(state, data) {
    state.enabled = true;
    state.logo = data.active_image.url;
    state.product = data.product;
    state.default_shipping_method = data.default_shipping_method ?? 'email';
  },
};

export const getters = {
  isEnabled(state) {
    return state.enabled;
  },
  whiteLabelProduct(state) {
    return state.product;
  },
  whiteLabelLogo(state) {
    return state.logo;
  },
};
