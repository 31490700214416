export const Alert = () => import('../../components/base/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const CookieStatement = () => import('../../components/base/CookieStatement.vue' /* webpackChunkName: "components/cookie-statement" */).then(c => wrapFunctional(c.default || c))
export const DynamicForm = () => import('../../components/base/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NumberInput = () => import('../../components/base/NumberInput.vue' /* webpackChunkName: "components/number-input" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/user.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const FormsCompanyInformationGroup = () => import('../../components/forms/CompanyInformationGroup.vue' /* webpackChunkName: "components/forms-company-information-group" */).then(c => wrapFunctional(c.default || c))
export const FormsCreateAccountGroup = () => import('../../components/forms/CreateAccountGroup.vue' /* webpackChunkName: "components/forms-create-account-group" */).then(c => wrapFunctional(c.default || c))
export const FormsPersonalInformationGroup = () => import('../../components/forms/PersonalInformationGroup.vue' /* webpackChunkName: "components/forms-personal-information-group" */).then(c => wrapFunctional(c.default || c))
export const FormsShippingInformationGroup = () => import('../../components/forms/ShippingInformationGroup.vue' /* webpackChunkName: "components/forms-shipping-information-group" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddToCart = () => import('../../components/modals/AddToCart.vue' /* webpackChunkName: "components/modals-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnableTwoFactorModal = () => import('../../components/modals/EnableTwoFactorModal.vue' /* webpackChunkName: "components/modals-enable-two-factor-modal" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeft = () => import('../../components/icons/ArrowLeft.vue' /* webpackChunkName: "components/icons-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowRight = () => import('../../components/icons/ArrowRight.vue' /* webpackChunkName: "components/icons-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconsBox = () => import('../../components/icons/Box.vue' /* webpackChunkName: "components/icons-box" */).then(c => wrapFunctional(c.default || c))
export const IconsBuyAnyGiftcardLogo = () => import('../../components/icons/BuyAnyGiftcardLogo.vue' /* webpackChunkName: "components/icons-buy-any-giftcard-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsCheck = () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/icons-check" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronDown = () => import('../../components/icons/ChevronDown.vue' /* webpackChunkName: "components/icons-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronLeft = () => import('../../components/icons/ChevronLeft.vue' /* webpackChunkName: "components/icons-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronRight = () => import('../../components/icons/ChevronRight.vue' /* webpackChunkName: "components/icons-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsCreditCard = () => import('../../components/icons/CreditCard.vue' /* webpackChunkName: "components/icons-credit-card" */).then(c => wrapFunctional(c.default || c))
export const IconsDeliveryCar = () => import('../../components/icons/DeliveryCar.vue' /* webpackChunkName: "components/icons-delivery-car" */).then(c => wrapFunctional(c.default || c))
export const IconsDeliveryCardGreen = () => import('../../components/icons/DeliveryCardGreen.vue' /* webpackChunkName: "components/icons-delivery-card-green" */).then(c => wrapFunctional(c.default || c))
export const IconsDutchFlag = () => import('../../components/icons/DutchFlag.vue' /* webpackChunkName: "components/icons-dutch-flag" */).then(c => wrapFunctional(c.default || c))
export const IconsFilterOptions = () => import('../../components/icons/FilterOptions.vue' /* webpackChunkName: "components/icons-filter-options" */).then(c => wrapFunctional(c.default || c))
export const IconsFilterOptionsBlack = () => import('../../components/icons/FilterOptionsBlack.vue' /* webpackChunkName: "components/icons-filter-options-black" */).then(c => wrapFunctional(c.default || c))
export const IconsIdeal = () => import('../../components/icons/Ideal.vue' /* webpackChunkName: "components/icons-ideal" */).then(c => wrapFunctional(c.default || c))
export const IconsInformation = () => import('../../components/icons/Information.vue' /* webpackChunkName: "components/icons-information" */).then(c => wrapFunctional(c.default || c))
export const IconsMagnifyingGlass = () => import('../../components/icons/MagnifyingGlass.vue' /* webpackChunkName: "components/icons-magnifying-glass" */).then(c => wrapFunctional(c.default || c))
export const IconsMinus = () => import('../../components/icons/Minus.vue' /* webpackChunkName: "components/icons-minus" */).then(c => wrapFunctional(c.default || c))
export const IconsPenToSquare = () => import('../../components/icons/Pen-to-square.vue' /* webpackChunkName: "components/icons-pen-to-square" */).then(c => wrapFunctional(c.default || c))
export const IconsPlus = () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/icons-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsTrashcan = () => import('../../components/icons/Trashcan.vue' /* webpackChunkName: "components/icons-trashcan" */).then(c => wrapFunctional(c.default || c))
export const IconsUkFlag = () => import('../../components/icons/UkFlag.vue' /* webpackChunkName: "components/icons-uk-flag" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateInputFloatingLabel = () => import('../../components/formulate/InputFloatingLabel.vue' /* webpackChunkName: "components/formulate-input-floating-label" */).then(c => wrapFunctional(c.default || c))
export const FormulateRangeSlider = () => import('../../components/formulate/RangeSlider.vue' /* webpackChunkName: "components/formulate-range-slider" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const FormulateValue = () => import('../../components/formulate/Value.vue' /* webpackChunkName: "components/formulate-value" */).then(c => wrapFunctional(c.default || c))
export const TransitionHeight = () => import('../../components/transition/Height.vue' /* webpackChunkName: "components/transition-height" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbs = () => import('../../components/ui/Breadcrumbs.vue' /* webpackChunkName: "components/ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiBusinessCards = () => import('../../components/ui/BusinessCards.vue' /* webpackChunkName: "components/ui-business-cards" */).then(c => wrapFunctional(c.default || c))
export const UiCardBanner = () => import('../../components/ui/CardBanner.vue' /* webpackChunkName: "components/ui-card-banner" */).then(c => wrapFunctional(c.default || c))
export const UiCardOverview = () => import('../../components/ui/CardOverview.vue' /* webpackChunkName: "components/ui-card-overview" */).then(c => wrapFunctional(c.default || c))
export const UiFilterMenuMobile = () => import('../../components/ui/FilterMenuMobile.vue' /* webpackChunkName: "components/ui-filter-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const UiInformationText = () => import('../../components/ui/InformationText.vue' /* webpackChunkName: "components/ui-information-text" */).then(c => wrapFunctional(c.default || c))
export const UiNavigation = () => import('../../components/ui/Navigation.vue' /* webpackChunkName: "components/ui-navigation" */).then(c => wrapFunctional(c.default || c))
export const UiUspBox = () => import('../../components/ui/UspBox.vue' /* webpackChunkName: "components/ui-usp-box" */).then(c => wrapFunctional(c.default || c))
export const UiFooterComponent = () => import('../../components/ui/footerComponent.vue' /* webpackChunkName: "components/ui-footer-component" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionItem = () => import('../../components/ui/Accordion/Accordion-item.vue' /* webpackChunkName: "components/ui-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../components/ui/Accordion/Accordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiButtonsButtonPrimary = () => import('../../components/ui/Buttons/ButtonPrimary.vue' /* webpackChunkName: "components/ui-buttons-button-primary" */).then(c => wrapFunctional(c.default || c))
export const UiButtonsCarouselButtons = () => import('../../components/ui/Buttons/CarouselButtons.vue' /* webpackChunkName: "components/ui-buttons-carousel-buttons" */).then(c => wrapFunctional(c.default || c))
export const UiCardHolderCarrousel = () => import('../../components/ui/CardHolder/Carrousel.vue' /* webpackChunkName: "components/ui-card-holder-carrousel" */).then(c => wrapFunctional(c.default || c))
export const UiCardHolder = () => import('../../components/ui/CardHolder/index.vue' /* webpackChunkName: "components/ui-card-holder" */).then(c => wrapFunctional(c.default || c))
export const UiCarrousel = () => import('../../components/ui/Carrousel/Carrousel.vue' /* webpackChunkName: "components/ui-carrousel" */).then(c => wrapFunctional(c.default || c))
export const UiCarrouselItem = () => import('../../components/ui/Carrousel/CarrouselItem.vue' /* webpackChunkName: "components/ui-carrousel-item" */).then(c => wrapFunctional(c.default || c))
export const UiFaqCategory = () => import('../../components/ui/Faq/Category.vue' /* webpackChunkName: "components/ui-faq-category" */).then(c => wrapFunctional(c.default || c))
export const UiFaqItem = () => import('../../components/ui/Faq/Item.vue' /* webpackChunkName: "components/ui-faq-item" */).then(c => wrapFunctional(c.default || c))
export const UiHeadersHeaderHero = () => import('../../components/ui/Headers/HeaderHero.vue' /* webpackChunkName: "components/ui-headers-header-hero" */).then(c => wrapFunctional(c.default || c))
export const UiHeadersHeaderPrimary = () => import('../../components/ui/Headers/HeaderPrimary.vue' /* webpackChunkName: "components/ui-headers-header-primary" */).then(c => wrapFunctional(c.default || c))
export const UiHeadersHeaderSecondary = () => import('../../components/ui/Headers/HeaderSecondary.vue' /* webpackChunkName: "components/ui-headers-header-secondary" */).then(c => wrapFunctional(c.default || c))
export const UiHeroCardsRotated = () => import('../../components/ui/Hero/CardsRotated.vue' /* webpackChunkName: "components/ui-hero-cards-rotated" */).then(c => wrapFunctional(c.default || c))
export const UiHero = () => import('../../components/ui/Hero/Hero.vue' /* webpackChunkName: "components/ui-hero" */).then(c => wrapFunctional(c.default || c))
export const UiHeroPhoto = () => import('../../components/ui/Hero/Photo.vue' /* webpackChunkName: "components/ui-hero-photo" */).then(c => wrapFunctional(c.default || c))
export const UiInfoboxInfoBox = () => import('../../components/ui/Infobox/InfoBox.vue' /* webpackChunkName: "components/ui-infobox-info-box" */).then(c => wrapFunctional(c.default || c))
export const UiInfoboxInfoBoxContent = () => import('../../components/ui/Infobox/InfoBoxContent.vue' /* webpackChunkName: "components/ui-infobox-info-box-content" */).then(c => wrapFunctional(c.default || c))
export const UiInfoboxInfoBoxHeader = () => import('../../components/ui/Infobox/InfoBoxHeader.vue' /* webpackChunkName: "components/ui-infobox-info-box-header" */).then(c => wrapFunctional(c.default || c))
export const UiOrderTag = () => import('../../components/ui/Order/Tag.vue' /* webpackChunkName: "components/ui-order-tag" */).then(c => wrapFunctional(c.default || c))
export const UiOrder = () => import('../../components/ui/Order/index.vue' /* webpackChunkName: "components/ui-order" */).then(c => wrapFunctional(c.default || c))
export const UiPointsCollapse = () => import('../../components/ui/Points/Collapse.vue' /* webpackChunkName: "components/ui-points-collapse" */).then(c => wrapFunctional(c.default || c))
export const UiPoints = () => import('../../components/ui/Points/index.vue' /* webpackChunkName: "components/ui-points" */).then(c => wrapFunctional(c.default || c))
export const UiTabsTab = () => import('../../components/ui/Tabs/Tab.vue' /* webpackChunkName: "components/ui-tabs-tab" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/Tabs/Tabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
